<template>
  <main class="container">
    <MoebelCarousel/>
    <b-row>
      <b-col cols="12" class="h-100 content">
        <div
          class="mb-4"
          v-for="node in contentTree.children"
          :key="node.id"
        >
          <h2 class="h1">{{ node.name }}</h2>
          <product-tiles :content-node="node"/>
        </div>
        <hr>
        <div id="services">
          <div class="grid-gallery two-columns">
            <b-link href="/gallerie" class="service">
              <h3 style="bottom: 1.5rem;" class="moebel-color">Mehr Bilder?<br/><span class="second-title-color">Könnt ihr haben!</span></h3>
              <img class="free-image" src="/images/services/PM_Bilder_Polaroid_.png" alt="Polaroid von Bildern aus der Bildergalerie">
            </b-link>
            <b-link href="https://bodmer.swiss/produkte/verpackung" class="service">
              <h3 class="bodmer-color">Du brauchst Standard-Paletten oder Palettenrahmen?<br/>Die kriegst du hier.</h3>
              <img class="logo" src="/images/LogoBodmerAGgestempelt.svg" alt="Logo Bodmer AG">
              <img class="free-image floating-pallet" src="/images/services/Palette_Rahmen.png" alt="Palette mit Rahmen">
            </b-link>
          </div>
          <hr>
          <div class="grid-gallery">
            <div class="span-full-column">
              <b-link to="/labels">
                <img src="/images/labels/Labels.jpg" alt="Labels: HPE, IPPC, VHPI ASPV, Holz CH">
              </b-link>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </main>
</template>

<script>
import {groupBy} from "lodash";
import MoebelCarousel from "../../components/home/MoebelCarousel";
import * as UiUtil from "../../util/ui/ui-util";
import * as OrmConstants from "../../model/orm-constants";
import ProductTiles from "@/components/product/ProductTiles";

export default {
  components: {
    ProductTiles,
    MoebelCarousel,
  },
  asyncData(context) {
    try {
      const productTypes = [];
      const category2ProductTypes = groupBy(productTypes, OrmConstants.productType.categoryId);
      return {
        category2ProductTypes,
      };
    } catch (e) {
      UiUtil.redirectError(context, e);
    }
  },
  data() {
    return {
      userText: this.$auth.isLoggedIn(), // this.loggedIn ? `${this.$auth()} ${this.user.lastName}` : `${this.$auth()}`
    };
  },
  computed: {
    contentTree() {
      return this.$store.getters["navigation/contentTree"];
    },
    categories() {
      return this.$store.getters["navigation/categories"];
    },
  },
};
</script>
