<template>
  <b-carousel
    id="header-carousel"
    class="moebel-color-mix"
    indicators
    slide
    :img-width="100"
    :img-height="100"
    :interval="6000"
  >
    <!-- Neuste Bilder ausprogrammiert -->
    <div class="carousel-item">
      <div class="teaser">
        <h3 class="caption">
          Dein Garten ist bereit.<br>Bist du es auch?<br><span
          class="second-title-color"
        >Blumenkisten und Hochbeete</span>
        </h3>
        <div class="floating-teaser-container">
          <img
            src="~/assets/images/moebel/Hochbeete_2.png"
            alt="Blumenkiste aus Palettenholz"
          >
          <img
            class="floating-teaser-image-label"
            src="/images/labels/CH_Holz_Bodmer_01.svg"
            alt="Bodmer Label: Nachhaltiges Schweizer Holz"
          >
        </div>
        <div class="moebel-bottom-signature">
          <img class="moebel-logo" src="~/assets/images/moebel/Palettenblock.svg" alt="Palettenmöbel Logo">
          <div class="green-border"/>
        </div>
      </div>
    </div>
    <div class="carousel-item">
      <div class="teaser">
        <h3 class="caption">
          Palettenmöbele dein Wohnzimmer auf.<br><span class="second-title-color">Jetzt nach eigenen Massen konfigurieren - in 3D.</span>
        </h3>
        <div class="floating-teaser-container">
          <img src="~/assets/images/moebel/Sofa_2.png" alt="Sofa aus Palettenholz">
          <img
            class="floating-teaser-image-label"
            src="/images/labels/CH_Holz_Bodmer_01.svg"
            alt="Bodmer Label: Nachhaltiges Schweizer Holz"
          >
        </div>
        <div class="moebel-bottom-signature">
          <img class="moebel-logo" src="~/assets/images/moebel/Palettenblock.svg" alt="Palettenmöbel Logo">
          <div class="green-border"/>
        </div>
      </div>
    </div>
    <div class="carousel-item">
      <div class="teaser">
        <h1 class="h3 caption">
          Palettenmöbel ganz nach deinen Wünschen.<br>Und nach deinen Massen.<br><span class="second-title-color">Jetzt konfigurieren - in 3D.</span>
        </h1>
        <div class="floating-teaser-container">
          <img src="~/assets/images/moebel/Kisten_2.png" alt="Blumenkiste aus Palettenholz">
          <img
            class="floating-teaser-image-label"
            src="/images/labels/CH_Holz_Bodmer_01.svg"
            alt="Bodmer Label: Nachhaltiges Schweizer Holz"
          >
        </div>
        <div class="moebel-bottom-signature">
          <img class="moebel-logo" src="~/assets/images/moebel/Palettenblock.svg" alt="Palettenmöbel Logo">
          <div class="green-border"/>
        </div>
      </div>
    </div>
    <!-- Neuste Bilder-->
    <!--
    <b-carousel-slide
      class="carousel"
      img-src="~/assets/images/moebel/Karussell_1270x320_Paletten.jpg"
      img-alt="Blumenkiste und Hochbeet"
    />
    <b-carousel-slide
      class="carousel"
      img-src="~/assets/images/moebel/Karussell_1270x320_Paletten_2.jpg"
      img-alt="Palettenmöbel-Tisch und Sitzbank"
    />
    <b-carousel-slide
      class="carousel"
      img-src="~/assets/images/moebel/Karussell_1270x320_Paletten_3.jpg"
      img-alt="Kisten mit Rollen in individuellen Grössen"
    />
    -->
    <!-- Alte Bilder -->
    <!--
        <b-carousel-slide
          class="carousel"
          caption="115 Jahre Bodmer AG"
          img-src="~/assets/images/BodmerAg.jpg"
        >
          <p>Bewährte Qualität: Wir sind die Nummer 1 in der Schweiz für Verpackungsmaterial!</p>
          <b-btn>Erfahren Sie mehr</b-btn>
        </b-carousel-slide>
        <b-carousel-slide
          class="carousel"
          img-src="~/assets/images/moebel/Teaser_Palettenmoebel.png"
        >
        </b-carousel-slide>
        <b-carousel-slide
          class="carousel"
          img-src="~/assets/images/moebel/Teaser_Palettenmoebel_2.png"
        >
        </b-carousel-slide>
        -->
  </b-carousel>
</template>

<script>
export default {
  name: "Carousel",
};
</script>
